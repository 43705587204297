
.info {
  padding-top: 0.5em;
  background-color: rgba(160, 158, 95, 0.034);
  border-radius: 0 0 2em 2em;
}

.info p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .info p {
    font-size: 0.4em;
  }
}


.info button {
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.info button:hover {
  background-color: rgba(255, 255, 255, 0.068);
}

.info button:focus {
  outline: none;
}


.network p {
  color: rgb(201, 157, 119);
  font-size: 0.8em;
}

.info li {
  color: rgb(201, 157, 119);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .info li {
    font-size: 0.4em;
  }
}


.info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}





.special h1 {
  margin-top: 1.2em;
}
.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.inputDiv {
  padding: 2em;
  height: 3em;
  align-items: center;
}

.input {
  height: 3em;
  width: 23em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.totals {
  margin-top: 3em;
  height: 3em;
}

.goMax {
  cursor: pointer;
}

.goMax h5 {
  font-size: 2em;
}


.box div {
  width: 280px;
  margin: auto;
}

.box p {
  color: rgb(201, 157, 119);
  font-size: 0.8em;
}

.box a {
  color: rgb(201, 157, 119);
  text-decoration: none;
}



.boxHigh div {
  width: 280px;
  margin: auto;
}

.boxHigh p {
  color: white;
  font-size: 0.8em;
}


.digButton {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.digButton:hover {
  background-color: rgba(200,2000,200,0.3);
}


.digButton:focus {
  outline: none;
 }
 .digButton p {
   display: inline;
   position: relative;
   bottom: 0.5em;
 }



 .blockButton {
   background-color: rgba(200,2000,200,0.1);
   color: white;
   font-size: 1em;
   border: white solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 12em;
   margin-top: 1em;
   cursor: pointer;
 }

 .blockButton:hover {
   background-color: rgba(200,2000,200,0.3);
 }


 .blockButton:focus {
   outline: none;
  }
  .blockButton p {
    display: inline;
    position: relative;
    bottom: 0.5em;
  }



.digIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}



.checkButton {
  background-color: rgba(200,200,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 4em;
  margin-top: 1em;
  cursor: pointer;
}

.checkButton:hover {
  background-color: rgba(200,200,200,0.3);
}

.checkButton:focus {
  outline: none;
 }

 .checkButton:disabled,
 .checkBbutton[disabled]{
   color: rgba(200,200,200,0.9);
 }

.checkIcon {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.checkButton p {
  display: inline;
  bottom: 0.5em;
}




.allButton {
  background-color: rgba(200,2000,200,0.1);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 3em;
  margin-top: 1em;
  cursor: pointer;
}

.allButton:hover {
  background-color: rgba(200,2000,200,0.3);
}


.allButton:focus {
  outline: none;
 }

.allButton p {
   display: inline;
   position: relative;
 }



 .scanButton {
   background-color: rgba(200,2000,200,0.1);
   color: white;
   font-size: 1em;
   border: white solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   margin-bottom: 1em;
   cursor: pointer;
 }

 .scanButton:hover {
   background-color: rgba(200,2000,200,0.3);
 }


 .scanButton:focus {
   outline: none;
  }

 .scanButton p {
    display: inline;
    position: relative;
  }


.larger {
  font-size: 1.2em;
}

.stone {
  position: relative;
  top: 2em;
}

.animatestone
{
  background-image: linear-gradient(
    50deg,
    #574315 0%,
    #7a2110 20%,
    #ff1361 30%,
    #fff800 40%,
    #ffffff 40%,
    #7a2110 80%,
    #574315 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: -200% center;
  }
}

.Grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}



.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}


.Child {
  min-width: 400px;
  min-height: 600px;
  max-width: 600px;

  background: linear-gradient(
      16deg,
      rgb(56, 30, 11) 0%,
      rgb(73, 37, 10) 42%,
      rgb(95, 45, 8) 100%
    ),
    linear-gradient(to right, rgb(99, 62, 37), rgb(70, 31, 11));
  border: 0.3em solid rgba(139, 60, 23, 0.514);
  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  border-radius: 2.5em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  -webkit-box-shadow: 0px 0px 800px 20px rgba(99, 75, 19, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(99, 75, 19, 0.44);
}

@media only screen and (max-device-width: 400px) {
  .Child {
    min-width: 0;
  }
}

.curtain {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
  color: rgb(201, 157, 119);
  text-decoration: none;
}
